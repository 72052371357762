import React, { useState, useRef } from "react";
import {Container,Row,Col,Card,Button,Modal,Form,} from "react-bootstrap";
import { RemoveScroll } from "react-remove-scroll";
import { ToastContainer, toast } from "react-toastify";
import { FaCircle } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "emailjs-com";
import LogoItem from "../Images/LogoItem.png";
import "../Style/Price.css";
import "../Style/MainPage.css"; // Подключаем стили для MainPage
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { MdOndemandVideo } from "react-icons/md";
import { MdVideoCameraFront } from "react-icons/md";
import { FaSchool } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";

const Price = () => {
  const [show1, setShow1] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");
  const form1 = useRef();
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.2 });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
      },
    }),
  };

  const handleShow1 = (course) => {
    setSelectedCourse(course);
    setShow1(true);
  };

  const handleClose1 = () => {
    setShow1(false);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    if (value) {
      setPhoneError("");
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError("Будь ласка, введіть номер телефону.");
    }
  };

  const handlePhoneFocus = () => {
    setPhoneError("");
  };

  // ------------------------------
  // Отримуємо utm-мітки з URL параметрів
  const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);

    let utm_source, utm_medium, utm_campaign, utm_content, utm_term;

    if (urlParams.has("utm_source")) {
      utm_source = urlParams.get("utm_source");
    } else {
      utm_source = "";
    }
    if (urlParams.has("utm_medium")) {
      utm_medium = urlParams.get("utm_medium");
    } else {
      utm_medium = "";
    }
    if (urlParams.has("utm_campaign")) {
      utm_campaign = urlParams.get("utm_campaign");
    } else {
      utm_campaign = "";
    }
    if (urlParams.has("utm_content")) {
      utm_content = urlParams.get("utm_content");
    } else {
      utm_content = "";
    }
    if (urlParams.has("utm_term")) {
      utm_term = urlParams.get("utm_term");
    } else {
      utm_term = "";
    }

    return {
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      utm_content: utm_content,
      utm_term: utm_term,
    };
  };
  // ---------------------------------- By Mykyta

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phone.trim() === "") {
      setPhoneError("Будь ласка, введіть номер телефону");
      return;
    }

    setPhoneError(""); // Очистить ошибку, если все в порядке
    console.log("Form submitted"); // Поставьте сюда ваш код отправки данных формы

    const formData = new FormData(form1.current);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      course: formData.get("course"),
    };

    emailjs
      .send("service_5gqx2v7", "template_uqtsohp", data, "4upStKj7ORtxgJNce")
      .then((response) => {
        toast.success("Повідомлення успішно відправлено!"); // Показать уведомление об успешной отправке
        setShow1(false); // Закрыть модальное окно сразу после успешной отправки
      })
      .catch((error) => {
        toast.error("Помилка надсилання повідомлення, спробуйте ще раз."); // Показать уведомление об ошибке
      });

    // -------------------------------
    const utmData = getUTMParameters();

    // Дані для відправки у форматі JSON
    const crmTgData = {
      event: event.type,
      button_id: event.target.id,
      timestamp: new Date().toISOString(),
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      interested_in: formData.get("course"),
      utm_source: utmData.utm_source,
      utm_medium: utmData.utm_medium,
      utm_campaign: utmData.utm_campaign,
      utm_content: utmData.utm_content,
      utm_term: utmData.utm_term,
    };

    // Відправка даних за допомогою fetch
    fetch("https://red.bidata.store/unext/landing/lead/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(crmTgData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((crmTgData) => {
        console.log("Success");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // ------------------------------ By Mykyta

    setShow1(false); // Закрыть модальное окно независимо от результата отправки
  };

  return (
    <Container className="my-4">
      <h1 className="text-center  title-price">
        Підберіть для себе комфортне навчання
      </h1>
      <Row>
        <Col lg={6} md={6} className="mb-4">
          <motion.div
            ref={ref1}
            custom={1}
            initial="hidden"
            animate={inView1 ? "visible" : "hidden"}
            variants={variants}
            className="h-100"
          >
            <Card className="h-100 card-custom">
              <Card.Body className="d-flex flex-column">
                <div className="card-header-custom d-flex align-items-center">
                  <FaSchool />
                  &nbsp;&nbsp;Офлайн заняття
                </div>
                <Card.Subtitle className="mb-2 text-muted mini-title-subject-card">
                  Заняття 2 рази на тиждень по 1,5 год.
                </Card.Subtitle>
                <Card.Text>
                  <ul className="block-subject-card">
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Прямий контакт
                      з досвідченими викладачами та негайний зворотний зв'язок.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Спілкування та
                      співпраця з одногрупниками для обміну знаннями та
                      підтримки.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Навчання в
                      структурованому середовищі з чітким розкладом занять.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Користуйся
                      індивідуальним підходом викладача, який адаптує матеріал
                      та методику під потреби групи.
                    </li>
                  </ul>
                </Card.Text>
                <div className="mt-auto block-price-button">
                  <div className="price">від 143 грн./годину</div>
                  <div className="total-price">від 2025 грн./місяць</div>
                  <Button
                    variant="secondary"
                    onClick={() => handleShow1("Офлайн заняття")}
                  >
                    Записатися
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
        <Col lg={6} md={6} className="mb-4">
          <motion.div
            ref={ref2}
            custom={0}
            initial="hidden"
            animate={inView2 ? "visible" : "hidden"}
            variants={variants}
            className="h-100"
          >
            <Card className="h-100 card-custom">
              <Card.Body className="d-flex flex-column">
                <div className="card-header-custom d-flex align-items-center">
                  <MdVideoCameraFront />
                  &nbsp;&nbsp;Онлайн в групі
                </div>
                <Card.Subtitle className="mb-2 text-muted mini-title-subject-card">
                  Заняття 2 рази на тиждень по 1,5 год.
                </Card.Subtitle>
                <Card.Text>
                  <ul className="block-subject-card">
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Гнучкий графік
                      та навчання з будь-якого місця.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Інтерактивні
                      технології, відео конференції, віртуальні дошки.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Взаємна
                      підтримка. Спілкування та співпраця з одногрупниками.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Економія часу
                      та коштів, відсутність потреби у поїздках.
                    </li>
                  </ul>
                </Card.Text>
                <div className="mt-auto block-price-button">
                  <div className="price">від 124 грн./годину</div>
                  <div className="total-price">від 1750 грн./місяць</div>
                  <Button
                    variant="secondary"
                    onClick={() => handleShow1("Онлайн в групі")}
                  >
                    Записатися
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
      <Row className="second-block-price">
        <Col lg={6} md={6} className="mb-4">
          <motion.div
            ref={ref3}
            custom={1}
            initial="hidden"
            animate={inView3 ? "visible" : "hidden"}
            variants={variants}
            className="h-100"
          >
            <Card className="h-100 card-custom">
              <Card.Body className="d-flex flex-column">
                <div className="card-header-custom d-flex align-items-center">
                  <MdOndemandVideo /> &nbsp;&nbsp;Онлайн в записі
                </div>
                <Card.Text>
                  <ul className="block-subject-card">
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Перегляд відео
                      та конспектів скільки завгодно.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Регулярні тести
                      для закріплення знань.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Доступність
                      матеріалів, всі ресурси завжди під рукою.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Можливість
                      навчатися будь-де і будь-коли.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Доступний курс
                      за вигідною ціною.
                    </li>
                  </ul>
                </Card.Text>
                <div className="mt-auto block-price-button">
                  <div className="price">від 65 грн./годину</div>
                  <div className="total-price">від 810 грн./місяць</div>
                  <Button
                    variant="secondary"
                    onClick={() => handleShow1("Онлайн в записі")}
                  >
                    Записатися
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
        <Col lg={6} md={6} className="mb-4">
          <motion.div
            ref={ref4}
            custom={2}
            initial="hidden"
            animate={inView4 ? "visible" : "hidden"}
            variants={variants}
            className="h-100"
          >
            <Card className="h-100 card-custom">
              <Card.Body className="d-flex flex-column">
                <div className="card-header-custom d-flex align-items-center">
                  <BsPeopleFill />
                  &nbsp;&nbsp;Індивідуальні заняття
                </div>
                <Card.Text>
                  <ul className="block-subject-card">
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> 100% уваги
                      викладача та персональний план навчання.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Навчання у
                      власному темпі та з акцентом на сильні та слабкі сторони.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Користуйся
                      гнучким графіком занять, що підходить саме для тобі.
                    </li>
                    <li className="text-subject-card">
                      <FaCircle style={{ fontSize: "0.4em" }} /> Досягни
                      максимального результату завдяки персоналізованому
                      підходу.
                    </li>
                  </ul>
                </Card.Text>
                <div className="mt-auto block-price-button">
                  <div className="price">500 грн./годину</div>
                  <Button
                    variant="secondary"
                    onClick={() => handleShow1("Індивідуальні заняття")}
                  >
                    Записатися
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <RemoveScroll enabled={show1}>
        <Modal show={show1} onHide={handleClose1} backdrop="true" centered>
          <Modal.Header closeButton>
            <Modal.Title className="titleModal">
              Записатися на курс &nbsp;
              <img src={LogoItem} className="logoAfterText" alt="LogoItem" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-MainPage">
            <Form ref={form1} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="textModal">Ваше імʼя:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Будь ласка, введіть ваше ім'я"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="textModal">Номер телефону:</Form.Label>
                <PhoneInput
                  country={"ua"}
                  value={phone}
                  onChange={handlePhoneChange}
                  onBlur={handlePhoneBlur}
                  onFocus={handlePhoneFocus}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: false,
                    className: "phone-input-field",
                  }}
                  containerClass="phone-input-container"
                />
                {phoneError && <div className="text-danger">{phoneError}</div>}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="textModal">
                  Електронна адреса:
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Будь ласка, введіть електронну адресу"
                />
              </Form.Group>
              <Form.Group
                controlId="formBasicCourse"
                style={{ display: "none" }}
              >
                <Form.Label className="textModal">Обраний курс:</Form.Label>
                <Form.Control
                  type="text"
                  name="course"
                  value={selectedCourse}
                  readOnly
                />
              </Form.Group>

              <Form.Group controlId="dataProcessingAgreement">
                <Form.Check
                  type="checkbox"
                  label="Натискаючи кнопку, я даю згоду на обробку персональних даних."
                  required
                />
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                  Закрити
                </Button>
                <Button type="submit" variant="secondary">
                  Відправити
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </RemoveScroll>
      <ToastContainer position="top-right" />
    </Container>
  );
};

export default Price;
